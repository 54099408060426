var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('versions.actions.search'),"solo":"","clearable":"","dense":"","rounded":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.majorVersions,"items-per-page":50,"footer-props":{
                            'items-per-page-options': [10, 20, 50, 100, 200],
                            'show-current-page': true,
                            'show-first-last-page': true,
                        }},scopedSlots:_vm._u([{key:"item.development",fn:function(ref){
                        var item = ref.item;
return [(item.development)?_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('projects.versions.development'))+" ")]):_vm._e(),(!item.development)?_c('v-chip',{attrs:{"color":"primary","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('projects.versions.outAt'))+" "+_vm._s(_vm.formatDate(item.date))+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.openVersion(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(_vm.$t('buttons.versionDetail'))+" ")],1)]}},{key:"item.lastFix",fn:function(ref){
                        var item = ref.item;
return [(item.lastFix)?[_vm._v(" "+_vm._s(item.lastFix.name)+" "),_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.openVersion(item.lastFix)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(_vm.$t('buttons.versionDetail'))+" ")],1)]:_vm._e()]}},{key:"item.currentFix",fn:function(ref){
                        var item = ref.item;
return [(item.currentFix)?[_vm._v(" "+_vm._s(item.currentFix.name)+" "),_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.openVersion(item.currentFix)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(_vm.$t('buttons.versionDetail'))+" ")],1)]:_vm._e()]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","text":"","small":""},on:{"click":function($event){return _vm.openDocumentation(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-book-open-page-variant-outline")]),_vm._v(" "+_vm._s(_vm.$t('buttons.versionDocumentation'))+" ")],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }