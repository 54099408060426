<template>
    <v-row>
        <v-col cols="10" offset="1">
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    <v-text-field
                        v-model="search"
                        :placeholder="$t('versions.actions.search')"
                        solo
                        clearable
                        dense
                        rounded
                        prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-card class="mb-4">
                        <v-data-table
                            :headers="headers"
                            :items="majorVersions"
                            :items-per-page="50"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 50, 100, 200],
                                'show-current-page': true,
                                'show-first-last-page': true,
                            }"
                        >
                            <template v-slot:[`item.development`]="{ item }">
                                <v-chip v-if="item.development" dark label small>
                                    {{ $t('projects.versions.development') }}
                                </v-chip>
                                <v-chip color="primary" v-if="!item.development" dark label small>
                                    {{ $t('projects.versions.outAt') }} {{ formatDate(item.date) }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name }}
                                <v-btn color="primary" text small @click="openVersion(item)">
                                    <v-icon left>mdi-tag</v-icon>
                                    {{ $t('buttons.versionDetail') }}
                                </v-btn>
                            </template>
                            <template v-slot:[`item.lastFix`]="{ item }">
                                <template v-if="item.lastFix">
                                    {{ item.lastFix.name }}
                                    <v-btn color="primary" text small @click="openVersion(item.lastFix)">
                                        <v-icon left>mdi-tag</v-icon>
                                        {{ $t('buttons.versionDetail') }}
                                    </v-btn>
                                </template>
                            </template>
                            <template v-slot:[`item.currentFix`]="{ item }">
                                <template v-if="item.currentFix">
                                    {{ item.currentFix.name }}
                                    <v-btn color="primary" text small @click="openVersion(item.currentFix)">
                                        <v-icon left>mdi-tag</v-icon>
                                        {{ $t('buttons.versionDetail') }}
                                    </v-btn>
                                </template>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="success" text small @click="openDocumentation(item)">
                                    <v-icon left>mdi-book-open-page-variant-outline</v-icon>
                                    {{ $t('buttons.versionDocumentation') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <!--<v-col cols="12" md="4" v-for="(version, index) in majorVersions" :key="index">
                    <v-card class="mb-4">
                        <v-card-title>
                            <span>{{ version.label ? version.label : version.name }}</span>
                            <v-spacer />
                            <v-chip :color="color(version)" dark label small>
                                <v-icon left dark small v-if="version.status === 'major'">mdi-check-decagram</v-icon>
                                <v-icon left dark small v-if="version.status === 'minor'">mdi-alert-decagram</v-icon>
                                <v-icon left dark small v-if="version.status === 'fix'">mdi-decagram</v-icon>
                                {{ $t('lists.versionStatus.' + version.status) }}
                            </v-chip>
                        </v-card-title>
                        <v-card-subtitle v-if="version.development">
                            {{ $t('projects.versions.development') }}
                        </v-card-subtitle>
                        <v-card-subtitle v-if="!version.development">
                            {{ $t('projects.versions.outAt') }} {{ formatDate(version.date) }}
                        </v-card-subtitle>

                        <v-card-actions>
                            <v-btn color="primary" text small @click="openVersion(version)">
                                <v-icon left>mdi-tag</v-icon>
                                {{ $t('buttons.versionDetail') }}
                            </v-btn>
                            <v-btn color="success" text small @click="openDocumentation(version)">
                                <v-icon left>mdi-book-open-page-variant-outline</v-icon>
                                {{ $t('buttons.versionDocumentation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>-->
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateMixin } from '@/mixins/dates';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'project-versions',
    data: () => ({
        search: undefined,
    }),
    mixins: [formatDateMixin, projectMemberMixin],
    components: {},
    computed: {
        ...mapGetters(['project']),
        headers() {
            return [
                {
                    text: this.$t('projects.versions.status'),
                    sortable: false,
                    width: 50,
                    value: 'development',
                },
                {
                    text: this.$t('projects.versions.name'),
                    sortable: false,
                    width: 300,
                    value: 'name',
                },
                {
                    text: this.$t('projects.versions.lastFix'),
                    sortable: false,
                    width: 300,
                    value: 'lastFix',
                },
                {
                    text: this.$t('projects.versions.currentFix'),
                    sortable: false,
                    width: 300,
                    value: 'currentFix',
                },
                {
                    text: '',
                    sortable: false,
                    width: 150,
                    value: 'actions',
                },
            ];
        },
        allowNewDocumentationLink() {
            return this.memberRoles.some((r) => ['productOwner', 'productManager', 'developer'].includes(r));
        },
        majorVersions() {
            if (this.project) {
                const versions = this.project.versions.filter((v) => {
                    if (v.status === 'major') {
                        if (this.search) {
                            return v.name.toLowerCase().includes(this.search.toLowerCase());
                        } else {
                            return true;
                        }
                    }
                    return false;
                });
                return versions
                    .map((v) => {
                        v.lastFix = this.versionFix(v, false);
                        v.currentFix = this.versionFix(v, true);
                        return v;
                    })
                    .sort((a, b) => {
                        const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();
            }
            return [];
        },
        versions() {
            if (this.project) {
                const versions = this.project.versions.filter((v) => {
                    if (this.search) {
                        return v.name.toLowerCase().includes(this.search.toLowerCase());
                    } else {
                        return true;
                    }
                });
                return versions
                    .sort((a, b) => {
                        const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();
            }
            return [];
        },
    },
    methods: {
        color(version) {
            let color = '';
            if (version.status === 'major') {
                color = 'light-blue darken-1';
            }
            if (version.status === 'minor') {
                color = 'light-green darken-1';
            }
            if (version.status === 'fix') {
                color = 'lime darken-1';
            }
            return color;
        },
        openVersion(version) {
            this.$router.push({
                name: 'project-version',
                params: { project_id: this.project.id, version: version.id },
            });
        },
        openDocumentation(version) {
            this.$router.push({
                name: 'project-docs',
                params: { project_id: this.project.id, version: version.id },
            });
        },
        versionFix(version, development) {
            if (this.project) {
                const versions = this.project.versions.filter((v) => {
                    if (v.status !== 'major' && v.parent === version.name && v.development === development) {
                        return true;
                    }
                    return false;
                });
                const treeVersions = versions
                    .sort((a, b) => {
                        const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();

                return treeVersions.length > 0 ? treeVersions[0] : undefined;
            }

            return undefined;
        },
    },
};
</script>
